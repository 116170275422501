<template>
  <div class="step1">
    <h2 class="text-center my-3 my-sm-5">Sign Up</h2>
    <b-form
      @submit.prevent="nextForm"
      class="w-100 needs-validation"
      novalidate
    >
      <div class="row mb-2 mb-lg-4">
        <div class="col-12 col-md-6">
          <div class="container">
            <b-form-group
              id="input-group-1"
              label="Mailing Address 1:"
              label-for="input-1"
            >
              <div class="inputField">
                <b-form-input
                  id="input-1"
                  v-model="form.mailAddress1"
                  aria-describedby="input-1-live-feedback"
                  :state="validateState('mailAddress1')"
                  required
                  ref="mailAddress1"
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback"
                  >This field is required</b-form-invalid-feedback
                >
              </div>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Mailing Address 2:"
              label-for="input-2"
            >
              <div class="inputField">
                <b-form-input
                  id="input-2"
                  v-model="form.mailAddress2"
                  aria-describedby="input-2-live-feedback"
                  :state="validateState('mailAddress2')"
                  required
                  ref="mailAddress2"
                ></b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >This field is required</b-form-invalid-feedback
                >
              </div>
            </b-form-group>

            <b-form-group id="input-group-3" label="City:" label-for="input-4">
              <div class="inputField">
                <b-form-input
                  id="input-3"
                  v-model="form.city"
                  placeholder="City"
                  aria-describedby="input-3-live-feedback"
                  :state="validateState('city')"
                  ref="city"
                ></b-form-input>
                <b-form-invalid-feedback id="input-3-live-feedback"
                  >This field is required</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="container">
            <b-form-group
              id="input-group-4"
              label="State or Provincial:"
              label-for="input-4"
            >
              <div class="inputField">
                <b-form-input
                  id="input-4"
                  v-model="form.state"
                  placeholder="State"
                  aria-describedby="input-4-live-feedback"
                  :state="validateState('state')"
                  ref="state"
                ></b-form-input>
                <b-form-invalid-feedback id="input-4-live-feedback"
                  >This field is required</b-form-invalid-feedback
                >
              </div>
            </b-form-group>

            <b-form-group
              id="input-group-5"
              label="Zip or Postal Code:"
              label-for="input-5"
            >
              <div class="inputField">
                <b-form-input
                  id="input-5"
                  type="tel"
                  placeholder="Zip Code"
                  v-model="form.zipcode"
                  aria-describedby="input-5-live-feedback"
                  :state="validateState('zipcode')"
                  required
                  ref="zipcode"
                ></b-form-input>
                <b-form-invalid-feedback id="input-5-live-feedback"
                  >Please enter correct zipcode</b-form-invalid-feedback
                >
              </div>
            </b-form-group>

            <b-form-group
              id="input-group-6"
              label="Country:"
              label-for="input-6"
            >
              <div class="inputField">
                <b-form-input
                  id="input-6"
                  v-model="form.country"
                  placeholder="Country"
                  aria-describedby="input-6-live-feedback"
                  :state="validateState('country')"
                  ref="country"
                ></b-form-input>
                <b-form-invalid-feedback id="input-6-live-feedback"
                  >This field is required</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="nextBtnContainer w-100 text-center">
        <b-button
          type="submit"
          variant="primary"
          class="nextBtn w-100 mt-4 mb-3"
          @click="submit"
        >
          Next
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, numeric } from "vuelidate/lib/validators";

import axios from "axios";
export default {
  mixins: [validationMixin],

  data() {
    return {
      form: {
        mailAddress1: "",
        mailAddress2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
      },
    };
  },
  validations: {
    form: {
      mailAddress1: {},
      mailAddress2: {},
      city: {},
      state: {},
      zipcode: {
        numeric,
      },
      country: {},
    },
  },
  mounted() {
    for (let ref in this.$refs) {
      // Initialize the map.
      const input = this.$refs[ref].$el;
      const autocomplete = new google.maps.places.Autocomplete(input);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        place.address_components.forEach((element) => {
          if (element.types[0] === "country") {
            this.form.country = element.long_name;
          }
          if (element.types[0] === "administrative_area_level_1") {
            this.form.state = element.long_name;
          }
          if (element.types[0] === "administrative_area_level_2") {
            this.form.city = element.long_name;
          }
          if (element.types[0] === "postal_code") {
            this.form.zipcode = element.long_name;
          }
        });
      });
    }
  },
  methods: {
    submit() {
      var storeData = this.$store.getters.getData;
      var step3data = {
        id: storeData.id,
        mobileNo: storeData.mobileNo,
        address: [
          {
            addr1: this.form.mailAddress1,
            addr2: this.form.mailAddress2,
            zipcode: this.form.zipcode,
            city: this.form.city,
            state: this.form.state,
            country: this.form.country,
            firstName: storeData.firstName,
            lastName: storeData.lastName,
            mobileNo: storeData.mobileNo,
          },
        ],
        step3Complete: true,
        twilioUserId: storeData.twilioUserId,
      };
      this.$store.dispatch("setData", step3data);

      console.log(this.$store.getters.getData);
      axios
        .put("https://api.vaxtraxglobal.com/api/v1/user", step3data, {
          headers: { role: "user" },
        })
        .then((result) => {
          console.log("step 3 completed");
          console.log(result.data);
          this.$store.dispatch("setData", result.data);

          this.$router.push("/dashboard");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    nextForm() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      // this.$router.push("step-4");
    },
  },
};
</script>

<style lang="scss" scoped>
.step1 {
  width: 100%;
  height: 100%;

  .container {
    padding: 0 5vw;

    .form-group {
      font-size: 0.9rem !important;
    }

    label {
      font-size: 14px;
    }
    input {
      border-radius: 90px;
      box-shadow: 0px 5px 9px #33333326;
      border: 1px solid #ebebeb;
      padding: 0 20px;
    }

    select {
      appearance: none;
      background-color: transparent;
      border: none;
      padding: 0 10px;
      margin: 0;
      width: 100%;
      font-family: inherit;
      font-size: inherit;
      cursor: inherit;
      line-height: inherit;
      outline: none;

      &::-ms-expand {
        display: none;
      }

      &:focus,
      &:active {
        border-color: transparent;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0),
          0 0 8px rgba(102, 175, 233, 0);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0),
          0 0 8px rgba(102, 175, 233, 0);
      }
    }

    .citySelect {
      width: 100%;
      border: 1px solid var(--select-border);
      border-radius: 0.25em;
      padding: 0 0.5em;
      cursor: pointer;
      line-height: 1.1;
      border-radius: 90px;
      box-shadow: 0px 5px 9px #33333326;
      border: 1px solid #ebebeb;
    }
  }

  .nextBtn {
    border-radius: 90px;
    box-shadow: 0px 5px 9px #33333326;
    max-width: 350px;
    min-width: 200px;
  }
}
</style>
